import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./contents/Home";
import About from "./contents/About";
import Services from "./contents/Services";
import Team from "./contents/Team";
import Jobs from "./contents/Jobs";
import Contact from "./contents/Contact";
import Clients from "./contents/Clients";
import "./assets/css/style.css";

function App() {
  return (
  
    <BrowserRouter>
      <Header />
      <Routes>
       
          <Route index element={<Home />} />
          <Route path="about" element={ <About />} />
          <Route path="services" element={<Services />} />
          <Route path="team" element={<Team />} />
          <Route path="clients" element={<Clients />} />
          <Route path="jobs" element={<Jobs />} />
          {/* <Route path="contact" element={<Contact />} /> */}
          {/* <Route path="*" element={<Home />} /> */}
      
      </Routes>
      <Footer />
    </BrowserRouter>
   
   );
}

export default App;




